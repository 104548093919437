import React from 'react'
import ArchiveWrapper from '../../../components/ArchiveWrapper/ArchiveWrapper'
import { graphql } from 'gatsby'
import StyledGallery from '../../../components/StyledGallery/StyledGallery'
import ReturnToPrevPage from '../../../components/ReturnToPrevPage/ReturnToPrevPage'
import Img from 'gatsby-image'
import MonumentContentWrapper from '../../../components/MonumentContentWrapper/MonumentContentWrapper'
import { Header1 } from '../../../components/Headers/Headers'
import SEO from '../../../components/seo'
import VideoPlayer from '../../../components/VideoPlayer/VideoPlayer'

const MinskMazowieckiCmentarzZydowski = ({ data }) => {
  const images = data.allFile.edges.map(({ node }) => node.childImageSharp)
  return (
    <ArchiveWrapper>
      <SEO title="Cmentarz Żydowski w Mińsku Mazowieckim" />
      <ReturnToPrevPage to={'/gminy/minsk-mazowiecki'}>
        Materiały dotyczące miasta Mińsk Mazowiecki oraz otaczającej go gminy
      </ReturnToPrevPage>
      <Header1>Cmentarz Żydowski w Mińsku Mazowieckim</Header1>
      <Img fluid={data.file.childImageSharp.fluid} />
      <MonumentContentWrapper>
        <p>
          Po społeczności Żydowskiej w Mińsku Mazowieckim pozostało niewiele
          pamiątek. Domy nie zdradzają swoich przedwojennych żydowskich
          właścicieli. Spacery z Historią które prowadzi w Mińsku Ewa Borkowska
          uchylają rąbka tajemnicy. Najbardziej widocznym śladem jest tzw Nowy
          Cmentarz Żydowski. Nekropolia w ciszy i cieniu drzew zabiera nas do
          czasów gdy miasto i kraj nie były religijnym monolitem.
        </p>
        <p>
          Ograniczona ulicami 1 PLM, Dąbrówki i Mickiewicza nekropolia ma
          zachowane ok 500 macew, z których najstarsza pochodzi z 1875 roku. Na
          nieco ponad 1 ha powierzchni oprócz pochówków, których dokonywała
          żydowska diaspora pochowane są w nieoznaczonych mogiłach ciała żydów
          zamordowanych w czasie wojny przez Niemców i kolaborantów różnej
          narodowości. W Mińsku Mazowieckim w roku 1940 hitlerowcy utworzyli
          getto w którym przesiedlono żydowskich mieskzańców Kałuszyna,
          Stanisławowa, Dobrego, Kołbieli, Pabianic, Kalisza i Lipna. Na
          niewielki obszarze stłoczono w różnych okresach około 7 tys. osób.
          Podczas likwidacji getta w 1942 część ludzi, głównie niedołężnych lub
          próbujących ucieczki został zamordowanych na miejscu. Pozostawiono ok
          600 silnych mężczyzn, z których po wypełnieniu zdań większość
          rozstrzelano na terenie cmentarza. 10 stycznia 1943 pozostające przy
          życiu ok 250 zostało zgromadzonych w dawnej szkole i wraz z budynkiem
          spalono, strzelając do szukających ucieczki.
        </p>
        <p>
          Sama nazwa sugeruje że nie była to jedyna żydowska nekropolia. Stary
          cmentarz mieścił się przy ulicy Kazikowskiego, zniszczony przez
          Niemców w czasie drugiej wojny światowej. Na jego terenie obecnie
          znajduje się Liceum Ekonomiczne. Szczątki wydobywane w czasie budowy
          były przenoszone na teren obecnego kirkutu.
        </p>
      </MonumentContentWrapper>
      <StyledGallery images={images} />
    </ArchiveWrapper>
  )
}

export const query = graphql`
  {
    file(
      name: { regex: "/featured/" }
      relativePath: { regex: "/minsk-mazowiecki/cmentarz-zydowski/" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allFile(
      filter: {
        relativePath: { regex: "/minsk-mazowiecki/cmentarz-zydowski/" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            thumb: fluid(maxWidth: 270, maxHeight: 270) {
              ...GatsbyImageSharpFluid
            }
            full: fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default MinskMazowieckiCmentarzZydowski
